.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f3f3f3;
  background: url("../../images/bg.jpg") no-repeat;
  background-size: 100% auto;
}

.login .login-form {
  width: 320px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 30px;
  opacity: 0.9;
}

.login .login-form .login-logo {
  width: 100%;
  height: 50px;
  background: url("../../images/logo.png") no-repeat 60px center/15%;
  margin-bottom: 20px;
  background-position-x: 0px;
  background-position-y: 10px;
}

.login .login-form .login-logo .login-name {
  text-align: left;
  padding-left: 50px;
  font-size: 18px;
}

.login-form-forgot {
  float: right;
}