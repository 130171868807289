.customize {
  .logo {
    height: 36px;
    margin:14px 10px;

    h1{
      color:#fff;
      line-height:36px;
      padding-left:5px;
      margin-bottom:0px;
    }
  }
}

.customize .logo h1.small{
  font-size:16px;
}

.customize .logo h1.large{
  font-size:24px;
}

.customize .user{
  float:right;
  margin-right:35px;
  // background-color: '#AAAAAA';
  span{
    font-size: 28px;
  }
}

.customize header{
  padding: 0;
  height: 70px;
  background: #f1f1f1;

  border-bottom: 1px #666666 solid;
}
.customize header .user{
  margin-right: 50px;
}

.customize header .user .user_head{
  background-color: #AAAAAA;
  vertical-align: middle

}

.customize footer{
  text-align: center;
}


